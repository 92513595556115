<template>
  <div class="m1">
    <div class="m1-h">
      <h2>异常用户列表</h2>
      <div class="m1-btns">
        <el-input v-model="form.user_key_id" style="width:169px; margin-right:20px" placeholder="请输入用户ID"></el-input>
        <el-input v-model="form.id" style="width:169px; margin-right:20px" placeholder="请输入IP"></el-input>
        <el-select v-model="form.times" style="width:169px; margin-right:20px" placeholder="请选择工种">
          <el-option label="全部" value=""></el-option>
          <el-option label="1s内超量获取" value="1"></el-option>
          <el-option label="5min内超量获取" value="300"></el-option>
          <el-option label="1h内超量获取" value="3600"></el-option>
          <el-option label="24h内超量获取" value="86400"></el-option>
        </el-select>
        <el-date-picker
          type="daterange"
          v-model="dates"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="margin-right: 20px;">
        </el-date-picker>
        <el-button type="primary" @click="getList()">查询</el-button>
        <el-button @click="reset()" plain type="primary">重置</el-button>
      </div>
    </div>
    <el-table
      border
      :data="data.data"
      style="width: 100%">
      <el-table-column
        prop="user_key_id"
        label="用户ID"
        width="180">
      </el-table-column>
      <el-table-column
        prop="nick_name"
        label="用户昵称"
        width="180">
      </el-table-column>
      <el-table-column
        prop="times"
        label="异常原因">
      </el-table-column>
      <el-table-column
        prop="counts"
        label="获取条数">
      </el-table-column>
      <el-table-column
        prop="ip"
        label="异常IP">
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="异常发生时间">
      </el-table-column>
      <el-table-column
        prop="lock_time"
        label="锁定时间">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 1">已封禁</span>
          <span v-else>{{scope.row.lock_time}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="处理">
        <template slot-scope="scope">
          <a @click="dialogVisible2 = true; editForm.id = scope.row.id; editForm.status = undefine" href="javascript:;" style="margin-right: 10px">更改锁定时间</a>
          <a v-if="scope.row.status == 0" @click="blocked(scope.row)" href="javascript:;">封禁</a>
          <a v-else @click="blocked(scope.row)" href="javascript:;">解禁</a>
        </template>
      </el-table-column>
    </el-table>

    <div class="m1-r1">
      <div>共<span class="m1-text1">{{data.total}}</span>条记录</div>
      <div>
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="getList()"
          :current-page.sync="form.page"
          :total="data.total">
        </el-pagination>
      </div>
    </div>

    <el-dialog
      title="更改锁定时间"
      :visible.sync="dialogVisible2"
      width="25%">
        <el-date-picker
          v-model="editForm.lock_time"
          type="datetime"
          placeholder="选择日期">
        </el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toSave()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  list, save
} from '@/api/abnormity';
import moment from 'moment';
export default {
  name: 'index',
  data() {
    return {
      dialogVisible2: false,
      form: {
        user_key_id: '',
        ip: '',
        times: '',
        begin_date: '',
        end_date: '',
        page: 1,
        page_size: 10,
      },
      editForm: {
        id: '',
        lock_time: '',
        status: ''
      },
      dates: [],
      data: {
        data: [12]
      }
    }
  },
  computed: {
  },
  created() {
    
  },
  mounted(){
    this.getList()
  },
  methods: {
    getList () {
      this.form.begin_date = this.dates && this.dates.length > 0 ? moment(this.dates[0]).format('YYYY-MM-DD') : '';
      this.form.end_date = this.dates && this.dates.length > 0 ? moment(this.dates[1]).format('YYYY-MM-DD') : '';
      list(this.form).then(res =>{
        this.data = res.data.data
      })
    },
    toSave () {
      if (this.editForm.lock_time) {
        this.editForm.lock_time = moment(this.editForm.lock_time).format('YYYY-MM-DD HH:mm:ss')
      }
      save(this.editForm).then(res =>{
        if (res.data.msg == 'ok') {
          this.dialogVisible2 = false;
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          });   
        }
      })
    },
    blocked (item) {
      this.editForm.id = item.id; 
      this.editForm.lock_time= undefined;
      this.editForm.status = item.status == 1 ? 0 : 1;
      this.toSave()
    },
    reset () {
      this.form = {
        user_key_id: '',
        ip: '',
        times: '',
        begin_date: '',
        end_date: '',
        page: 1,
        page_size: 10,
      }
      this.dates = []
    }
  },
}
</script>
<style scoped lang="scss">
.m1-h{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.m1-r1{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.m1-text1{
  color: #409EFF;
}
</style>
